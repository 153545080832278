import { render, staticRenderFns } from "./PriceRecordsList.vue?vue&type=template&id=05aa95bd&scoped=true&"
import script from "./PriceRecordsList.vue?vue&type=script&lang=js&"
export * from "./PriceRecordsList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05aa95bd",
  null
  
)

export default component.exports
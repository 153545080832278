<template>
  <div>
    <b-card
      no-body
      class="mb-0"
    >
      <b-table
        class="position-relative recordsTableView"
        primary-key="id"
        responsive
        show-empty
        striped
        hover
        empty-text="Não há atas registradas."
        :items="data"
        :fields="visibleFields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        :busy="isLoading"
        tbody-tr-class="recordsTableCustomHeight"
        @row-clicked="onRowSelected"
      >
        <template #table-busy>
          <div class="text-center my-3">
            <b-spinner
              variant="info"
              class="align-middle mb-1"
            />
            <br>
            <strong class="pt-2">Carregando...</strong>
          </div>
        </template>
        <template #cell(id)="field">
          <b-media vertical-align="center">
            <b-link
              :to="{ name: 'records-view', params: { id: field.item.id } } "
              class="font-weight-bold d-block text-nowrap"
            >
              #{{ field.item.id }}
            </b-link>
          </b-media>
        </template>

        <template #cell(client__name)="field">
          <div
            class="d-block text-nowrap"
          >
            {{ field.item.client_set.name }}
          </div>
        </template>

        <template #cell(trade_number)="field">
          <div
            class="d-block text-nowrap font-weight-bolder"
          >
            {{ field.item.trade_number }}
          </div>
        </template>

        <template #cell(uasg)="field">
          <div
            class="d-block text-nowrap font-weight-bolder"
          >
            {{ field.item.uasg }}
          </div>
        </template>

        <template #cell(price_record_expiration_date)="field">
          <div
            class="d-block text-nowrap"
          >
            {{ formatDate(field.item.price_record_expiration_date) }}
          </div>
        </template>

        <template #cell(status__name)="field">
          <div
            class="d-block text-nowrap"
          >
            <b-badge
              v-if="formatDateWithStatus(field.item.price_record_expiration_date)"
              pill
              class="text-capitalize"
              :style="{
                'background-color': formatDateWithStatus(field.item.price_record_expiration_date) === 'vencido' ? '#FF3030' : '#049F0A'
              }"
            >
              {{ formatDateWithStatus(field.item.price_record_expiration_date) }}
            </b-badge>
            <label v-else>-</label>
          </div>
        </template>

        <template #cell(actions)>
          <div
            class="checkboxfix"
          >
            <b-form-checkbox
              :checked="false"
            />
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            class="d-flex align-items-center justify-content-center"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="filter.page_size"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BMedia,
  BLink,
  BPagination,
  BBadge,
  BSpinner,
  VBTooltip,
  BFormCheckbox,
} from 'bootstrap-vue'

import {
  useToast,
} from 'vue-toastification/composition'

import moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import syslic from '@/syslic'
import SyncLoad from '@/components/utils/syncLoad'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BMedia,
    BLink,
    BPagination,
    BBadge,
    BSpinner,
    BFormCheckbox,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    filter: {
      type: Object,
      required: true,
    },
    active: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  emits: ['ready'],

  data() {
    const totalItems = 0
    const currentPage = 1
    const sortBy = 'date'
    const isSortDirDesc = false
    const selectedBid = null

    const tableColumns = [
      {
        key: 'id',
        label: 'Id',
        visible: true,
        fixedColumn: true,
        thClass: 'text-nowrap',
        thStyle: { minWidth: '6rem' },
      },
      {
        key: 'client__name',
        label: 'Órgão',
        visible: true,
        fixedColumn: false,
        thStyle: { minWidth: '10rem' },
      },
      {
        key: 'trade_number',
        label: 'Nº Pregão',
        visible: true,
        fixedColumn: false,
        thStyle: { minWidth: '10rem' },
      },
      {
        key: 'uasg',
        label: 'UASG',
        visible: true,
        fixedColumn: false,
        thStyle: { minWidth: '10rem' },
      },
      {
        key: 'price_record_expiration_date',
        label: 'Validade',
        visible: true,
        fixedColumn: false,
        thStyle: { minWidth: '8rem' },
      },
      {
        key: 'status__name',
        label: 'Status',
        visible: true,
        fixedColumn: false,
        thStyle: { minWidth: '8rem' },
      },
      {
        key: 'actions',
        label: 'Exportar',
        visible: true,
        fixedColumn: true,
        thStyle: { minWidth: '8rem' },
      },
    ]

    const data = []
    const userImages = []
    const lastSearch = ''
    const isOverdue = false

    const isLoading = true
    const syncLoad = new SyncLoad()
    syncLoad.addTrigger('bidding')

    syncLoad.onReady(() => {
      this.isLoading = false
      this.$emit('ready', true)
    })

    const fetchId = ''

    return {
      totalItems,
      currentPage,
      sortBy,
      isSortDirDesc,
      tableColumns,
      data,
      userImages,
      lastSearch,
      syncLoad,
      isLoading,
      fetchId,
      selectedBid,
      isOverdue,
    }
  },
  computed: {
    hasPermissionToRead() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.bidding.can_read
    },
    hasPermissionToWrite() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.bidding.can_write
    },
    hasPermissionToUpdate() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.bidding.can_edit
    },
    hasPermissionToDelete() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.bidding.can_delete
    },
    visibleFields() {
      return this.tableColumns.filter(field => field.visible)
    },
    ajustableFields() {
      const fields = []
      this.tableColumns.forEach((element, index) => {
        if (!element.fixedColumn) {
          fields.push({
            index,
            label: element.label,
          })
        }
      })

      return fields
    },
  },
  watch: {
    filter: {
      handler: function updateFilter(val) {
        this.currentPage = 1
        if (this.lastSearch !== val.search) {
          this.lastSearch = val.search
        }

        this.fetchData()
      },
      deep: true,
    },
    sortBy: function updateSortBy() {
      this.currentPage = 1
      this.fetchData()
    },
    isSortDirDesc: function updateIsSortDirDesc() {
      this.currentPage = 1
      this.fetchData()
    },
    currentPage: function updateCurrentPage() {
      this.fetchData()
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    refreshColumns(columns) {
      columns.forEach(element => {
        this.tableColumns.forEach((obj, i) => {
          if (obj.key === element.key) {
            this.tableColumns[i].visible = element.visible
          }
        })
      })
    },
    fetchData() {
      const fetchPending = Math.random()
      this.fetchId = fetchPending
      this.isLoading = true
      const filterBidding = this.filter
      const typeSRP = 1

      filterBidding.p = this.currentPage
      filterBidding.type = typeSRP
      filterBidding.is_homologated = true

      if (this.isSortDirDesc) {
        filterBidding.ordering = `-${this.sortBy}`
      } else {
        filterBidding.ordering = this.sortBy
      }

      syslic
        .bidding
        .fetchBiddings(filterBidding)
        .then(response => {
          let promise = {}

          if (fetchPending === this.fetchId) {
            this.totalItems = response.data.count
            this.data = response.data.results
            promise = this.loadUsersPhotos()
          } else {
            promise = new Promise((resolve, reject) => {
              reject()
            })
          }

          return promise
        })
        .then(() => {
          this.syncLoad.ready('bidding')
        })
        .catch(() => {
          if (fetchPending === this.fetchId) {
            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Falha ao ler os dados das licitações.',
                text: 'Não foi possível ler os dados das licitações do servidor, por favor entre em contato com o administrador do sistema.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                timeout: false,
              },
            })
            this.syncLoad.ready('bidding')
          }
        })
    },
    loadUsersPhotos() {
      const users = []
      this.data.forEach(element => {
        users.push(element.owner_set.get_uuid)
      })

      const promiseList = []
      users.forEach(user => {
        if (!(user in this.userImages)) {
          const promise = new Promise((resolve, reject) => {
            syslic.user.getUserImage(user)
              .then(response => {
                this.userImages[user] = response
                resolve()
              })
              .catch(() => {
                reject()
              })
          })

          promiseList.push(promise)
        }
      })

      return Promise.allSettled(promiseList)
    },
    formatDate(date) {
      if (!date) {
        return '-'
      }
      const parsedDate = moment(date)

      if (parsedDate.isValid()) {
        return parsedDate.format('DD/MM/YYYY')
      }

      return '-'
    },
    formatDateWithStatus(date) {
      const parsedDate = moment(date)

      if (parsedDate.isValid()) {
        parsedDate.format('DD/MM/YYYY')
        if (parsedDate.isBefore(moment(), 'day')) {
          return 'vencido'
        }
        return 'válido'
      }
      return false
    },
    onRowSelected(item) {
      router.push({
        name: 'records-view',
        params: {
          id: item.id,
        },
      })
    },
  },
  setup() {
    const toast = useToast()

    return {
      toast,
      moment,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.recordsTableView {
  border-radius: .3rem;
}

.checkboxfix {
  display: flex;
  justify-content: center;
}

.recordsTableCustomHeight {
  height: 4.5rem;
}
</style>

<template>
  <div>
    <div v-show="isLoading">
      <spin-loader />
    </div>
    <div v-show="!isLoading">
      <b-row class="justify-content-center">
        <b-col
          cols="12"
          md="12"
          lg="12"
        >
          <bidding-filter
            ref="refBiddingFilter"
            :kanban-enable="false"
            :buttons-enable="false"
            :table-enable="false"
            :type-enable="false"
            :export-button-enable="true"
            :type-preset="typeSRP"
            :filter-saved="false"
            :visible-columns="tableColumns"
            @filter-change="updateFilter"
            @columns-change="updateColumns"
            @viewmode-change="updateViewMode"
            @ready="syncLoad.ready('filter')"
          />
        </b-col>
      </b-row>
      <b-row
        v-show="viewMode === viewModeTable"
        class="justify-content-center mt-1"
      >
        <b-col
          cols="12"
          md="12"
          lg="12"
        >
          <price-records-table
            ref="refPriceRecordsTable"
            :filter="filter"
            :active="viewMode === viewModeTable"
            @ready="syncLoad.ready('table')"
          />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  VBTooltip,
} from 'bootstrap-vue'

import syslic from '@/syslic'
import SyncLoad from '@/components/utils/syncLoad'
import SpinLoader from '@/components/spinloader/SpinLoader.vue'
import PriceRecordsTable from '@/views/bidding/price-records/PriceRecordsTable.vue'
import BiddingFilter from '@/views/bidding/components/BiddingFilter.vue'

export default {
  components: {
    BRow,
    BCol,
    SpinLoader,
    PriceRecordsTable,
    BiddingFilter,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    const filter = {
      page_size: 10,
      search: '',
    }

    const isLoading = true

    const syncLoad = new SyncLoad()
    syncLoad.addTrigger('table')
    syncLoad.addTrigger('filter')
    syncLoad.addTrigger('type')

    syncLoad.onReady(() => {
      this.isLoading = false
    })

    const tableColumns = [
      {
        key: 'id',
        label: 'Id',
        visible: true,
        fixedColumn: false,
      },
      {
        key: 'client__name',
        label: 'Órgão',
        visible: true,
        fixedColumn: false,
      },
      {
        key: 'trade_number',
        label: 'Nº Pregão',
        visible: true,
        fixedColumn: false,
      },
      {
        key: 'uasg',
        label: 'UASG',
        visible: true,
        fixedColumn: false,
      },
      {
        key: 'price_record_expiration_date',
        label: 'Validade',
        visible: true,
        fixedColumn: false,
      },
      {
        key: 'status__name',
        label: 'Status',
        visible: true,
        fixedColumn: false,
      },
      {
        key: 'actions',
        label: 'Exportar',
        visible: true,
        fixedColumn: true,
      },
    ]

    const viewModeTable = 'viewModeTable'
    const viewMode = viewModeTable
    const typeSRP = ''

    return {
      isLoading,
      filter,
      tableColumns,
      syncLoad,
      viewModeTable,
      viewMode,
      typeSRP,
    }
  },
  computed: {
    hasPermissionToWrite() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.bidding.can_write
    },
  },
  created() {
    this.fetchType()
  },
  methods: {
    fetchType() {
      const params = {
        name: 'SRP',
      }

      syslic
        .bidding
        .type
        .fetchTypes(params)
        .then(response => {
          this.typeSRP = JSON.parse(JSON.stringify(response.data.results[0]))
          this.syncLoad.ready('type')
          this.$emit('ready', true)
        })
        .catch(() => {
          this.syncLoad.ready('type')
          this.$emit('ready', true)
        })
    },
    updateColumns(val) {
      this.$refs.refPriceRecordsTable.refreshColumns(val)
    },
    updateFilter(val) {
      this.filter = val
    },
    updateViewMode(val) {
      this.viewMode = val
    },
  },
  setup() {
    return {
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
